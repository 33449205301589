<template>
  <div class="list-manage my-device">
    <section class="list-manage-table">
      <div class="table-title">
        <span class="title">我的设备</span
        ><span
          class="device-map"
          @click="$router.push('/personalCenter/deviceMap').catch(() => {})"
          ><i class="f-icon icon-location-orange"></i>设备地图</span
        >
      </div>
      <div class="tabs-bar">
        <div
          class="tabs-item"
          :class="{ selected: index == tabsCurrent }"
          v-for="(item, index) in tabsList"
          :key="index"
          @click="tabsChange(index)"
        >
          {{ item }}
        </div>
      </div>
      <div class="thead">
        <span class="th">设备</span>
        <span class="th">状态</span>
        <span class="th" style="text-align: center">操作</span>
      </div>
      <div class="tbody" v-loading="loading">
        <template v-if="this.Devicelist.length !== 0">
          <div class="list-item" v-for="index in Devicelist" :key="index.id">
            <div class="list-item-header">
              <span class="order-number"></span
              ><span class="status">{{
                index.audit_state == 1
                  ? "待审核"
                  : index.audit_state == 2
                  ? "审核通过"
                  : "审核失败"
              }}</span>
            </div>
            <div class="list-item-main">
              <div class="td device">
                <el-image
                  :src="index.show_img"
                  style="width: 60px; height: 60px; margin-right: 11px"
                >
                  <div slot="error" class="image-slot">
                    <img
                      src="~@/assets/images/temp/temp_03.png"
                      class="device-pic"
                      alt=""
                    />
                  </div> </el-image
                >{{ index.name }}
              </div>
              <div class="td">{{ index.status == 1 ? "正常" : "已下架" }}</div>
              <div
                class="td"
                style="
                  display: flex;
                  justify-content: space-around;
                  flex-wrap: wrap;
                "
              >
                <span class="btn" @click="goRevise(index)">修改</span>

                <span
                  class="btn"
                  @click="goEnd(index)"
                  style="background-color: #fa0041; color: #fff; border: 0"
                  >删除</span>
                <div v-if="index.audit_state == 2" style="margin-top: 5px">
                  <span
                    class="btn"
                    @click="downDevice(index)"
                    v-if="index.status == 1"
                    style="padding-top=10px"
                    >下架</span
                  >
                  <span class="btn" @click="upDevice(index)" v-else>上架</span>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div
          v-else-if="Devicelist.length === 0 && !loading"
          style="text-align: center"
        >
          暂无数据
        </div>
      </div>
    </section>
    <div class="pagination">
      <el-pagination
        background
        layout="total,prev, pager, next,jumper"
        :page-size="page.pageSize"
        :current-page="page.pageNumber"
        :total="total.totalRow"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getDevice, updateDevice,delDevice} from "../../api/myorder";
import kits from "@/kits/index.js";
export default {
  data() {
    return {
      loading: true,
      tabsCurrent: 0,
      tabsList: ["待审核", "已审核", "审核失败"],
      Devicelist: [], //所有列表
      page: {
        pageNumber: 1,
        pageSize: 10,
      },
      total: {
        totalPage: null,
        totalRow: null,
      },
    };
  },
  created() {
    this.getmydevice();
  },
  methods: {
    goRevise(index) {
      this.$router.push({
        path: `/reviseDevice`,
        query: { id: index.device_lease_id },
      });
    },
    handleCurrentChange(val) {
      this.page.pageNumber = val;

      this.getlist();
      window.scrollTo(0, 0);
    },
    getlist() {
      if (this.tabsCurrent == 0) {
        this.tabChange(this.tabsCurrent);
        this.loading = false;
        window.scrollTo(0, 0);
      } else if (this.tabsCurrent == 1) {
        this.tabChange(this.tabsCurrent);
        this.loading = false;
        window.scrollTo(0, 0);
      } else if (this.tabsCurrent == 2) {
        this.tabChange(this.tabsCurrent);
        this.loading = false;
        window.scrollTo(0, 0);
      } else {
        this.getmydevice();
      }
    },
    async tabChange(index) {
      this.loading = true;
      let res = await getDevice({
        inputs: { audit_state: index + 1 },
        pagination: this.page,
      });
      console.log(res);
      this.Devicelist = res.data.list;
      this.total.totalPage = res.data.totalPage;
      this.total.totalRow = res.data.totalRow;
      for (let i = 0; i < this.Devicelist.length; i++) {
        this.Devicelist[i].show_img = kits.img.url(this.Devicelist[i].show_img);
      }
    },
    tabsChange(index) {
      this.page.pageNumber = 1;
      this.tabsCurrent = index;

      if (this.tabsCurrent == 0) {
        this.tabChange(this.tabsCurrent);
      } else if (this.tabsCurrent == 1) {
        this.tabChange(this.tabsCurrent);
      } else if (this.tabsCurrent == 2) {
        this.tabChange(this.tabsCurrent);
      }
      this.loading = false;
    },
    async getmydevice() {
      let res = await getDevice({
        inputs: { audit_state: 1 },
        pagination: this.page,
      });
      console.log(res);

      this.Devicelist = res.data.list;
      this.total.totalPage = res.data.totalPage;
      this.total.totalRow = res.data.totalRow;
      for (let i = 0; i < this.Devicelist.length; i++) {
        this.Devicelist[i].show_img = kits.img.url(this.Devicelist[i].show_img);
      }
      this.loading = false;
    },
    downDevice(index) {
      updateDevice({
        inputs: { device_lease_id: index.device_lease_id, status: 2 },
      }).then((res) => {
        if (res.status === 200) {
          this.$message({
            message: "下架成功",
            type: "success",
          });
          this.getlist()
        } else {
          this.$message({
            message: "下架失败",
            type: "error",
          });
        }
      });
    },
    upDevice(index) {
      updateDevice({
        inputs: { device_lease_id: index.device_lease_id, status: 1 },
      }).then((res) => {
        if (res.status === 200) {
          this.$message({
            message: "上架成功",
            type: "success",
          });
          this.getlist()
        } else {
          this.$message({
            message: "上架失败",
            type: "error",
          });
        }
      });
    },
    async goEnd(index) {
      const confirm = await this.$confirm(
        "是否删除设备?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((error) => error);
      if (confirm !== "confirm") {
        return this.$message.info("您已取消删除");
      }
    this.deldevice(index)
    },
    deldevice(index){
      delDevice({device_lease_id: index.device_lease_id}).then((res) => {
        if (res.status === 200) {
          this.$message({
            message: "删除成功",
            type: "success",
          });
          this.getlist()
        } else {
          this.$message({
            message: "删除失败",
            type: "error",
          });
        }
      });
    }
  },
};
</script>

<style scoped>
.my-device .list-manage-table .thead .th:last-of-type,
.my-device .list-manage-table .tbody .td:last-of-type {
  width: 197px;
}
</style>
